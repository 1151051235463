import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-local-loading",
  templateUrl: "./local-loading.component.html"
})
export class LocalLoadingComponent implements OnInit {
  public lottieConfig: Object;

  public animationData = require("../../../../assets/animations/loader-box.json");

  constructor() {}
  ngOnInit() {
    this.lottieConfig = {
      animationData: this.animationData,
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }
}

// npm install @types/node --save-dev

import { Config } from "./../app.config";
import { Injectable, NgModule, Component } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: "root" 
})

export class WeAdminService {
  constructor(public config: Config, public httpClient: HttpClient, private datePipe: DatePipe) {}

  // https://api.sociogigante.com/admin/verify/documents/pending
  // GET: Retorna todos os usuários disponíveis

  // /admin/verify/documents/status
  // idpessoa: id
  // POST: Faz a verificação dos status dos documentos e me retorna true ou false

  public postBuscarUsuario(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/search/user", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public selectTitle(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
  
    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/selectTitle",data, {
        headers:_headers
      })
  }
  public postTransferTitle(data): Observable<any> {
  let _headers = new HttpHeaders({
    "Content-Type": "application/json"
  });

  return this.httpClient
    .post<any>(this.config.baseUrl + "admin/transferTitle",data, {
      headers:_headers
    })
};

  public getcheckplan(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json",
      
    });
  
    return this.httpClient
      .post<any>(this.config.baseUrl + "public/planbycontract",{
        headers:_headers,
        params: data
      })
  }
  /**
   * Busca usuários no banco
   */
  public postSerachUser(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/search/user-data", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
          //console.log("postLogin response: " + JSON.stringify(h));
        })
      );
  }

  /**
   * Gera Senha Temporária do Usuário
   */
  public generateTemporaryPassword(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/temp-passwd", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
          //console.log("postLogin response: " + JSON.stringify(h));
        })
      );
  }

  public postAprovarDocumentos(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/verify/documents/pending", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
         let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/contract/status", {
        headers: _headers,
        params: _params
      })
        })
      );
  }

  public checkCpf(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "meubilhete/checkuser", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }
  public countAllStatus(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/verify/documents/countAllStatus", {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getPendingUsers(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/verify/documents/pending", {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getApproved(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/verify/documents/approved", {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getDisapproved(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/verify/documents/disapproved", {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public postInserirStatusAoUsuario(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/verify/documents/status", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public contractUpdateDate(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/contract/date", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public getContractStatus(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/contract/status", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public contractStatusUpdate(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/contract/status", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public contractCancel(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/contract/cancel", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getContractGuests(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/contract/guests", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public contractGuests(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/contract/guests", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public getContractCancellationReasonsOptions(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/contract/cancellationreasons", {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getContractPaymentOptions(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/contract/paymentmethods", {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public docUsers(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/docusers", {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getContractPayment(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/contract/payment", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public updateContractPayment(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/contract/payment", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  // public ticketActivate(data): Observable<any> {
  //   let _headers = new HttpHeaders({
  //     "Content-Type": "application/json"
  //   });

  //   return this.httpClient
  //     .post<any>(this.config.baseUrl + "admin/ticket/activate", data, {
  //       headers: _headers
  //     })
  //     .pipe(
  //       tap(h => {
  //         console.log("postLogin response: " + JSON.stringify(h));
  //       })
  //     );
  // }

  public getTicketStatusOptions(): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/ticket/availablestatus", {
        headers: _headers
      })
      .pipe(
        tap(h => {
         // console.log("getTicketStatusOptions response: " + JSON.stringify(h));
        })
      );
  }
  
  public getTicketStatus(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/ticket/status", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public updateTicketStatus(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/ticket/status", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getTicketContract(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/ticket/contract", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public updateTicketContract(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/ticket/contract", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getInstallmentDescription(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/installment/description", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public changeNumberContract(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/changenumbercontract", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public selectContractNumber(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/selectcontractnumber", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public installmentsPartner(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/installmentspartner", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public transferInstallmentsPartner(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/transferinstallmentspartner", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public statusPartner(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/statuspartner", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public changeplanduration(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/planduration", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public updateDateInstallment(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/installment/updatedateinstallment", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public updateInstallmentDescription(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/installment/description", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getInstallmentStatus(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/installment/status", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }
  
  public updateInstallmentStatus(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/installment/status", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public getInstallmentValues(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    let _params = new HttpParams()
                      .set('id', data);

    return this.httpClient
      .get<any>(this.config.baseUrl + "admin/installment/values", {
        headers: _headers,
        params: _params
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public updateInstallmentValues(data): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .put<any>(this.config.baseUrl + "admin/installment/values", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
        })
      );
  }

  public transformDate(data) {
    return this.datePipe.transform(data, 'yyyy-MM-dd');
  }

}

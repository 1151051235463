import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WEUserSearchData } from '../../models/we-model';
import { WeAdminService } from '../../services/we-admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { SubSink } from 'subsink';
import { SearchService } from '../../shared/services/search.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  public subsink: SubSink = new SubSink;

  public showResults: boolean = false;

  public searchLoading: boolean = false;

  public displayedColumns: string[] = ['nome', 'email', 'cpf', 'dscestado_ativacao','dscpessoa_tipo','idpessoa', 'senha_temporaria'];
  public dataSource: MatTableDataSource<WEUserSearchData> = new MatTableDataSource();

  public searchForm: FormGroup;

  @ViewChild('searchField') searchField: ElementRef;

  constructor(public formBuilder: FormBuilder,
              public weAdminService: WeAdminService,
              public searchService: SearchService,
              private _clipboardService: ClipboardService,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy():void {

    this.subsink.unsubscribe();

  }

  /**
   * Inicializa Dados
   */
  initData() {

    this.searchForm = this.formBuilder.group({
      search: [null, Validators.minLength(3)]
    });

    this.subsink.add(
      this.searchService.getSearchResult().subscribe(
        res=>{
          this.dataSource.data = res
          if (res == null) {
            this.showResults = false;
            this.searchForm.reset();
          }
        },
        err=>console.error(err),
        ()=>{}
      )
    )

  }

  /**
   * Buscar Sócio
   * 
   * OBS.: Por CPF, Nome, E-mail ou Id Pessoa
   */
  search() {

    if (this.searchForm.valid && this.searchForm.value.search) {

      this.searchLoading = true;
      this.showResults = true;

      this.subsink.add(
        this.weAdminService.postSerachUser(this.searchForm.value).subscribe(
            res=>{
              this.searchService.setSearchResult(res.data);
            },
            err=>console.log(err),
            ()=>{
              this.searchLoading = false;
              this.showResults = true;
            }
          )
      );
    } else {
      (<any>Object).values(this.searchForm.controls).forEach(control => {
        control.markAsTouched();
        control.markAsDirty();
      });
    }
  }

  /**
   * Aplica Filtro a Tabela
   * @param filterValue 
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Gera Senha Temporária e copia para a área de transferência
   * 
   * @param idpessoa idpessoa que será gerada a senha
   */
  generateTemporaryPassword(idpessoa) {
    
    this.weAdminService.generateTemporaryPassword({idpessoa}).subscribe(
      res=>{
        this._clipboardService.copyFromContent(res.data);
      },
      err=>console.error(err),
      ()=>{
        this.toastr.success('Área de transferência', 'Senha Copiada');
      }
    )


  }

}

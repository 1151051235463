import { Injectable } from "@angular/core";

@Injectable()
export class Config {
  // - PRODUCTION
  // public baseUrl = "https://api.mtcportodave.com.br/";
  // - DEVELOPMENT
  // public baseUrl = "https://api.stg.mtcportodave.com.br/";
  // - LOCAL
  public baseUrl = "http://127.0.0.1:3333/";

  // Misc
  public version: string = "0.1.1";
}

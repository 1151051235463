import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WEUserSearchData } from '../../models/we-model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  //Search
  private readonly _searchbar: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly _searchbar$: Observable<boolean> = this._searchbar.asObservable();

  private readonly _searchResult: BehaviorSubject<WEUserSearchData[]> = new BehaviorSubject(null);
  readonly _searchResult$: Observable<WEUserSearchData[]> = this._searchResult.asObservable();

  constructor() {}

  /**
   * Searchbar Setter
   * 
   * @param _searchbar boolean
   */
  setSearchbar(_searchbar: boolean) {
    this._searchbar.next(_searchbar);
    if (!_searchbar) {
      this.clearSearchResult();
    }
  }

  /**
   * Searchbar Getter
   * 
   * @param _searchbar boolean
   */
  getSearchbar() {
    return this._searchbar$;
  }

  /**
   * searchResult Setter
   * 
   * @param {WEUserSearchData} _searchResult
   */
  setSearchResult(_searchResult: Array<WEUserSearchData>) {
    this._searchResult.next(_searchResult);
  }

  /**
   * searchResult Getter
   * 
   * @param {WEUserSearchData} _searchResult
   */
  getSearchResult() {
    return this._searchResult$;
  }

  clearSearchResult() {
    this._searchResult.next(null);
  }

}

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgModule, PLATFORM_ID, LOCALE_ID, APP_ID, Inject } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import localePt from "@angular/common/locales/pt";
import { HttpModule } from "@angular/http";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { isPlatformBrowser, registerLocaleData, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { Config } from "./app.config";

import {
  MetaModule,
  MetaLoader,
  MetaStaticLoader,
  PageTitlePositioning
} from "@ngx-meta/core";

// Services
import { LocalStorageService } from "./shared/services/local-storage.service";
import { AuthService } from "./shared/services/auth.service";

// Third Library
import { NgxMaskModule } from "ngx-mask";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { LottieAnimationViewModule } from "ng-lottie";
import { PinchZoomModule } from 'ngx-pinch-zoom';

// Helpers
import { JwtHelperService, JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { JwtInterceptor } from "./utils/jwt.interceptor";
import { ErrorInterceptor } from "./utils/error.interceptor";
import { HourMinutePipe } from "./utils/hourminute.pipe";
import { ErrorUtility } from "./utils/error-utility";

// Components
import { LoginComponent } from "./components/login/login.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DocumentsComponent } from "./components/documents/documents.component";
import { SidemenuComponent } from "./components/sidemenu/sidemenu.component";
import { HeaderComponent } from "./components/header/header.component";
import { DocumentsTableComponent } from "./shared/components/documents-table/documents-table.component";
import { ModalUserInfoComponent } from "./shared/components/modals/modal-user-info/modal-user-info.component";
import { LocalLoadingComponent } from "./shared/components/local-loading/local-loading.component";
import { MaterialModule } from './app.material.module';
import { FerramentasComponent, DialogCheckCpf } from './components/ferramentas/ferramentas.component';
import { SearchComponent } from './components/search/search.component';
import { MatIconModule, MatTableModule } from '@angular/material';
import { ClickOutsideModule } from 'ng-click-outside';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { TransferenciaTituloComponent, DialogOverviewExampleDialog } from './components/ferramentas/transferencia-titulo/transferencia-titulo.component';

registerLocaleData(localePt, "pt-BR");

export function tokenFactory(localStorage) {
  return {
    tokenGetter: () => {
      return localStorage.getItem("fengst-admin-token");
    }
  };
}

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: " | ",
    applicationName: "FENG Sistema Admin",
    defaults: {
      title: "FENG Sistema Admin",
      description: "CSM Brasil",
      "og:image":
        "https://fpf-space.nyc3.digitaloceanspaces.com/img/logo-big.png",
      "og:type": "website",
      "og:locale": "pt_BR"
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    DocumentsComponent,
    SidemenuComponent,
    HeaderComponent,
    DocumentsTableComponent,
    ModalUserInfoComponent,
    LocalLoadingComponent,
    HourMinutePipe,
    FerramentasComponent,
    SearchComponent,
    TransferenciaTituloComponent,
    DialogOverviewExampleDialog,
    DialogCheckCpf
  ],
  imports: [
    SweetAlert2Module.forRoot({
      showCancelButton: true,
      buttonsStyling: false,
      animation: false,
      customClass: "app-swal animated zoomIn faster",
      confirmButtonClass: "primary-button",
      cancelButtonClass: "secundary-button",
      cancelButtonText: "Cancelar"
    }),
    NgxMaskModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    HttpClientModule,
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PdfViewerModule,
    MaterialModule,
    MatIconModule,
    MatTableModule,
    ClickOutsideModule,
    ClipboardModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: tokenFactory,
        deps: [LocalStorageService]
      }
    }),
    PinchZoomModule
  ],

  providers: [
    JwtHelperService,
    LocalStorageService,
    Config,
    ErrorUtility,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalUserInfoComponent, LocalLoadingComponent,DialogCheckCpf,DialogOverviewExampleDialog]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    protected localStorage: LocalStorageService
  ) {
    const platform = isPlatformBrowser(platformId)
      ? "in the browser"
      : "on the server";
    //console.log(`Running ${platform} with appId=${appId}`);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hourMinute'
})
export class HourMinutePipe implements PipeTransform {

    transform(value: number, args?: any): string {
        let minutes: number =Math.floor(value % 3600 / 60);
        let hours: number = Math.floor(value / 3600);

        minutes += 1;
        
        if (hours < 9 && minutes < 9) {
            return `<div class="contagem-regressiva"><div class="numero">0</div><div class="numero">${hours}</div><div class="separador">:</div><div class="numero">0</div><div class="numero">${(minutes)}</div></div>`;
        }
        if (hours > 9 && minutes > 9) {
            return `<div class="contagem-regressiva"><div class="numero">${hours.toString().charAt(0)}</div><div class="numero">${hours.toString().charAt(1)}</div><div class="separador">:</div><div class="numero">${(minutes).toString().charAt(0)}</div><div class="numero">${(minutes).toString().charAt(1)}</div></div>`;
        }
        if (hours > 9 && minutes < 9) {
            return `<div class="contagem-regressiva"><div class="numero">${hours.toString().charAt(0)}</div><div class="numero">${hours.toString().charAt(1)}</div><div class="separador">:</div><div class="numero">0</div><div class="numero">${(minutes)}</div></div>`;
        }
        if (minutes > 9) {
            return `<div class="contagem-regressiva"><div class="numero">0</div><div class="numero">${hours}</div><div class="separador">:</div><div class="numero">${(minutes).toString().charAt(0)}</div><div class="numero">${(minutes).toString().charAt(1)}</div></div>`;
        }
    }
}
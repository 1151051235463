import { Config } from "./../app.config";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Subject } from "rxjs/index";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { JwtHelperService } from "@auth0/angular-jwt";
import { LocalStorageService } from "../shared/services/local-storage.service";
import { UserSignin, WeTokenResponse } from "../models/we-model";

@Injectable({
  providedIn: "root"
})
export class WeAuthService {
  public isAuth: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected localStorage: LocalStorageService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    protected jwtHelper: JwtHelperService,
    public config: Config,
    public httpClient: HttpClient
  ) {}

  public postLogin(data: UserSignin): Observable<any> {
    let _headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    return this.httpClient
      .post<any>(this.config.baseUrl + "admin/auth", data, {
        headers: _headers
      })
      .pipe(
        tap(h => {
          console.log("postLogin response: " + h);
        })
      );
  }
}

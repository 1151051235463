import { Component } from "@angular/core";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { AuthService } from "./shared/services/auth.service";

import { LoadingService } from "./shared/services/loading.service";
import { ScrollTopService } from "./shared/services/scrollTop.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [ScrollTopService]
})
export class AppComponent {
  public user: any;

  public isLoading: boolean = true;
  public isLoggedIn: Observable<boolean>;

  public sideMenuStatus: boolean = false;

  public selectedItemFromMenu: string;

  constructor(
    public loadingService: LoadingService,
    public scrollTopService: ScrollTopService,
    public authService: AuthService,
    public localStorage: LocalStorage,
    public router: Router
  ) {
    this.isLoggedIn = authService.isLoggedIn();
    this.scrollTopService.setScrollTop();
    this.checkAuth();
    this.loadingService.startLoading();

    this.loadingService.isLoading.subscribe(
      response => {
        this.isLoading = response;
      },
      error => {
        console.log(error);
      },
      () => {}
    );

    this.loadingService.fengUserUpdate.subscribe(
      response => {
        this.checkAuth();
      },
      error => {
        console.log(error);
      },
      () => {}
    );
  }

  checkAuth() {
    this.authService.isAuthenticated().subscribe(
      response => {
        this.handleAuth(response);
      },
      error => {
        console.log(error);
      },
      () => {}
    );
  }

  handleAuth(value) {
    if (value) {
      this.localStorage.getItem("fengst-admin-user").subscribe(
        response => {
          this.user = response;
        },
        error => {
          console.log(error);
        },
        () => {
          this.loadingService.finishLoading();
        }
      );
    } else {
      this.loadingService.finishLoading();
      this.router.navigate(["login"]);
    }
  }

  toggleSideMenu(event) {
    this.sideMenuStatus = event;
  }

  selectedItemMenuChanged(event) {
    if (event) {
      this.selectedItemFromMenu = event;
    }
  }
}

import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

import { LocalStorage } from "@ngx-pwa/local-storage";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LocalStorageService {
  public fengUserUpdate: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(PLATFORM_ID) protected platformId: Object,
    protected localStorage: LocalStorage
  ) {}

  setItem(key: string, value: any): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.localStorage.setItem(key, JSON.stringify(value));
    } else {
      return null;
    }
  }

  getItem(key: string): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.localStorage.getItem(key);
    } else {
      return null;
    }
  }

  removeItem(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.removeItem(key).subscribe(() => {});
    } else {
      return null;
    }
  }

  removeAll() {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.clear().subscribe(() => {});
    } else {
      return null;
    }
  }
}

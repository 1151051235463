import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { LocalStorageService } from "../shared/services/local-storage.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.localStorage.getItem("fengst-admin-token").pipe(
      mergeMap(token => {
        if (!request.url.includes("public")) {
          let tokenBearer = ''
          if (request.url.includes("meubilhete")) {
            token = "eyJpYXQiOjE1NTAwNjQwMDksImV4cCI6NDExMzE1ODQwMCwianRpIjoiM3EzRlpuNzMzOEJFYzVKSjhYbjYwNSIsIm1ldWJpbGhldGUiOnRydWUsInVzZXJJZCI6Ijc0MWQ0ZWMwYTNlMzRkMjVkNWUxNjhlN2Y2YWZmNGIwIn0";
            tokenBearer = token
          } else {
            if (token) {
              token = token.slice(1, -1);
              tokenBearer = `Bearer ${token}`
            }
          }
          const headers = new HttpHeaders({
            Authorization: tokenBearer
          });
          request = request.clone({ headers });
        }

        return next.handle(request);
      })
    );
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  constructor() { }

  public lottieConfig: Object;

  public animationData = require("../../../assets/animations/welcome.json");

  ngOnInit() {
    this.lottieConfig = {
      animationData: this.animationData,
      renderer: "canvas",
      autoplay: true,
      loop: false
    };
  }
}

import { Component, OnInit , Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import * as moment from "moment";


import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm
} from "@angular/forms";

import { WeAdminService } from "../../services/we-admin.service";
import { ErrorUtility } from '../../utils/error-utility';
import swal from "sweetalert2";
import { WeInstallmentValues } from "../../models/we-model"
import { MatInput } from '@angular/material';

export interface DialogData {
  result: any;
  displayedColumns: any;

}

@Component({
  selector: 'app-ferramentas',
  templateUrl: './ferramentas.component.html',
  styleUrls: ['./ferramentas.component.scss']
})
export class FerramentasComponent implements OnInit {

  @ViewChild('installmentDatePicker', {
    read: MatInput
  }) 
  installmentDatePicker: MatInput;
  public dataSource = new MatTableDataSource;
	public installmentsColumns: string[] = ['nome', 'dscconta_receber', 'valor','verified', 'selecionar'];

  public searchLoading: boolean = false;
  public displayedColumns: string[] = ['socio'];
public editContract: FormGroup;
  public contractUpdateDateForm: FormGroup;
  public contractStatusUpdateForm: FormGroup;
  public statusMember: FormGroup;
  public hideTable = false;
  public contractStatus: string;
  public idMember: string;
  public hasContractStatus: boolean = true;
  public contractStatusOptions: Array<Object>;

  public contratoCancelarForm: FormGroup;
  public contractCancellationReasonsOptions: Array<Object>;

  public contractGuestsForm: FormGroup;
  public contractGuests: string;
  public hasContractGuests: boolean = true;
  public updateContractPaymentForm: FormGroup;
  public hasContractPayment: boolean = true;
  public contractPayment: any;
  public contractPaymentMethodOptions: Array<Object>;
  public contractPaymentPeriodicityOptions: Array<Object>;
  public checkedId;
  public updateTicketStatusForm: FormGroup;
  public hasTicketStatus: boolean = true;
  public ticketStatus: String;
  public ticketStatusOptions: Array<Object>;
  public updateTicketContractForm: FormGroup;
  public hasTicketContract: boolean = true;
  public ticketContract: String;

  public cancelInstallmentForm: FormGroup;
  public updateInstallmentDescriptionForm: FormGroup;
  public updateInstallmentStatusForm: FormGroup;
  public updateInstallmentValuesForm: FormGroup;

  public integrationForm: FormGroup;

  public dateParcelsForm: FormGroup;
  public installmentTransfer: FormGroup;

  public hasInstallmentStatus: boolean = true;
  public installmentStatus: String;
  public installmentStatusOptions: Array<Object>;

  public hasInstallmentDescription: boolean = true;
  public installmentDescription: String;
  public resultInstallment: any;
  
  public hasInstallmentValues: boolean = true;
  public installmentValues: Object;
  public dataResult;
  public installmentResult = []
  public resultnumberContract: string;

  public installmentsUpdate: FormGroup;
  public checkSelectionForm: FormGroup;

  public installmentDate: Object;
  public changePlanDuration: Object;
  public duration: Object;

  public hide = true;
  allStatusContract: any;
  

  constructor(public formBuilder: FormBuilder,
              public weAdminService: WeAdminService,
              public dialog: MatDialog,
              public errorUtility: ErrorUtility) {

    this.installmentStatusOptions = [
      {
        "id":"AP",
        "dsc": "Aguardando pagamento"
      },{
        "id":"CA",
        "dsc": "Cancelada"
      },{
        "id":"PA",
        "dsc": "Paga"
      },{
        "id":"PC",
        "dsc": "Pendente cancelamento"
      },{
        "id":"TP",
        "dsc": "Tentativa pagamento"
      },
    ];

    this.installmentValues = {
      "valor": "",
      "valor_desconto": "",
      "valor_acrescimo": "",
      "valor_juros": "",
      "valor_multa": "",
      "valor_adesao": "",
      "valor_pago": "",
      "valor_reajuste": "",
      "valor_cancelado": ""
    };


    this.contractPayment = {
      "idcontrato":"",
      "idformapag": "",
      "idperiodicidade": "",
      "duracao":"",
    }

  }

  ngOnInit() {
    this.setFormGroups();
    this.setTicketStatusOptions();
    this.setContractPaymentOptions();
    this.setContractCancellationReasonsOptions();
    this.optionsDuration();
  }

  ngOnDestroy() {
    this.installmentDescription = null;
    this.installmentValues = null;
    this.hasInstallmentDescription = false;
    this.hasInstallmentValues = false;
  }

  setFormGroups() {
    this.contractUpdateDateForm = this.formBuilder.group({
      idcontrato: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      new_date: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      type: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
    });

    this.contractStatusUpdateForm = this.formBuilder.group({
      idcontrato: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      new_idestado_ativacao: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
    });

    this.contratoCancelarForm = this.formBuilder.group({
      idcontrato: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      idmotivo_cancel: [
        "",
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.contractGuestsForm = this.formBuilder.group({
      idcontrato: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      guests_num: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
    });

    this.updateContractPaymentForm = this.formBuilder.group({
      idcontrato: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      idformapag: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      idperiodicidade: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      duracao:[
        "",
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.updateTicketStatusForm = this.formBuilder.group({
      idcartao: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      status: [
        "",
        Validators.compose([
          Validators.required
        ])
      ]
    });
    
    this.updateTicketContractForm = this.formBuilder.group({
      idcartao: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      idcontrato: [
        "",
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.cancelInstallmentForm = this.formBuilder.group({
      idconta_receber: [
        "",
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.updateInstallmentDescriptionForm = this.formBuilder.group({
      idconta_receber: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      dscconta_receber: [
        "",
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.updateInstallmentStatusForm = this.formBuilder.group({
      idconta_receber: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      status: [
        "",
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.updateInstallmentValuesForm = this.formBuilder.group({
      idconta_receber: [
        this.installmentValues['idconta_receber'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor: [
        this.installmentValues['valor'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_desconto: [
        this.installmentValues['valor_desconto'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_acrescimo: [
        this.installmentValues['valor_acrescimo'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_juros: [
        this.installmentValues['valor_juros'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_multa: [
        this.installmentValues['valor_multa'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_adesao: [
        this.installmentValues['valor_adesao'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_pago: [
        this.installmentValues['valor_pago'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_reajuste: [
        this.installmentValues['valor_reajuste'],
        Validators.compose([
          Validators.required
        ])
      ],
      valor_cancelado: [
        this.installmentValues['valor_cancelado'],
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.integrationForm = this.formBuilder.group({
      cpf: [
       '',
        Validators.compose([
          Validators.required
        ])
      ],
      mifare: '',
      idPartida: ''     
    });

    this.dateParcelsForm = this.formBuilder.group({
      
      idContaReceber: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      newDate: [
        '',
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.statusMember = this.formBuilder.group({
      
      idPessoa: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      idStatus: [
        '',
        Validators.compose([
          Validators.required
        ])
      ]
    })

    this.editContract= this.formBuilder.group({
      idContrato: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      numContrato:[
        '',
        Validators.compose([
          Validators.required
        ])
      ]
      
    })

    this.installmentTransfer = this.formBuilder.group({

      idContratoOrigem: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      idContratoDestino: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      installmentId: [
        '',
      ],
    })

    this.installmentsUpdate = this.formBuilder.group({ 

      TransferOrigin: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      TransferDestiny: [
        '',
        Validators.compose([
          Validators.required
        ])
      ]

    })
  }

  

  setTicketStatusOptions() {
    this.weAdminService.getTicketStatusOptions().subscribe(
      response => {
        if(response.status) {
          this.ticketStatusOptions = response.result;
        }
      },
      error => this.errorUtility.showError(21, error),
      () => {}
    );
  }

  setContractPaymentOptions() {
    this.weAdminService.getContractPaymentOptions().subscribe(
      response => {
        if(response.status) {
          this.contractPaymentMethodOptions = response.result.payment_methods;
          this.contractPaymentPeriodicityOptions = response.result.periodicity;
        }
      },
      error => this.errorUtility.showError(21, error),
      () => {}
    );
  }

  sendContractDateUpdate() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.contractUpdateDateForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      // console.log(this.contractUpdateDateForm.value);
      
      if (this.contractUpdateDateForm.valid) {
        
        this.contractUpdateDateForm.value.new_date = this.weAdminService.transformDate(this.contractUpdateDateForm.value.new_date);
        
        this.weAdminService
          .contractUpdateDate(this.contractUpdateDateForm.value)
          .subscribe(
            response => {
              if (response.status) {
                let dateType = this.contractUpdateDateForm.value.type == 0 ? 'inicial' : 'final';
                swal({
                  title: "Sucesso",
                  text: "A data " + dateType +" do contrato foi atualizado com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  getContractStatus(id) {
    if(id != '') {
      this.weAdminService
        .getContractStatus(id)
        .subscribe(
          response => {
            this.contractStatus = response.result;
            this.hasContractStatus = response.status;
            this.allStatusContract = response.allStatusContract;
          },
          error => this.errorUtility.showError(21, error),
          () => {
          }
        );
    }
  }

  sendContractStatusUpdate() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.contractStatusUpdateForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.contractStatusUpdateForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .contractStatusUpdate(this.contractStatusUpdateForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "O status do contrato foi atualizado com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  setContractCancellationReasonsOptions() {
    this.weAdminService.getContractCancellationReasonsOptions().subscribe(
      response => {
        if(response.status) {
          this.contractCancellationReasonsOptions = response.result;
        }
      },
      error => this.errorUtility.showError(21, error),
      () => {}
    );
  }

  sendContractCancellation() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.contratoCancelarForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.contratoCancelarForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .contractCancel(this.contratoCancelarForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "O contrato foi cancelado com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  sendStatus(){
    this.searchLoading = true;
    let data = {
     'idPessoa': this.statusMember.value.idPessoa,
     'idStatus':this.statusMember.value.idStatus
    }

   
    this.weAdminService.statusPartner(data).subscribe(
      
      response => {
        if(response.status){
          swal({
            title: "Sucesso",
            text: "Status do Sócio atualizado com sucesso!",
            type: "success",
            buttonsStyling: false,
            animation: false,
            customClass: "app-swal animated zoomIn faster",
            confirmButtonClass: "primary-button",
            cancelButtonClass: "secundary-button",
            confirmButtonText: "OK"
          }).then(result => {
             if (result.value) {
                location.reload();
              }
            });
      }else {
        this.searchLoading = false;
        this.errorUtility.showError(null, response.mensagem)
      }
    },err=>{        
        this.searchLoading = false;
        this.showAlert(
          "Erro de consulta",
          "Pessoa não encontrada",
          "error"
          )
        },
      () => {
        this.searchLoading = false;
        
      }
    );

  }

  getContractGuests(id) {
    if(id != '') {
      this.weAdminService
        .getContractGuests(id)
        .subscribe(
          response => {
            this.contractGuests = response.result;
            this.hasContractGuests = response.status;
          },
          error => this.errorUtility.showError(21, error),
          () => {
          }
        );
    }
  }

  sendContratoGuests() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.contractGuestsForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.contractGuestsForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .contractGuests(this.contractGuestsForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "Os convidados do contrato foram alterados com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  getContractPayment(id) {
    if(id != '') {
      this.weAdminService
        .getContractPayment(id)
        .subscribe(
          response => {
            this.hasContractPayment = response.status;
            this.contractPayment = response.result;
          },
          error => this.errorUtility.showError(21, error),
          () => { }
        );
    }
  }

  sendUpdadeContractPayment() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.updateContractPaymentForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.updateContractPaymentForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .updateContractPayment(this.updateContractPaymentForm.value)
          .subscribe(
            response => {
              
              if (response.status) {

                this.planDuration()
                
                swal({
                  title: "Sucesso",
                  text: "Forma de pagamento, periodicidade e duracao do plano atualizados com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }


  getTicketStatus(id) {
    if(id != '') {
      this.weAdminService
        .getTicketStatus(id)
        .subscribe(
          response => {
            this.ticketStatus = response.result;
            this.hasTicketStatus = response.status;
          },
          error => this.errorUtility.showError(21, error),
          () => {
          }
        );
    }
  }

  sendUpdadeTicketStatus() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.updateTicketStatusForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.updateTicketStatusForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .updateTicketStatus(this.updateTicketStatusForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "Status da cartão ingresso atualizado com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  getTicketContract(id) {
    if(id != '') {
      this.weAdminService
        .getTicketContract(id)
        .subscribe(
          response => {
            this.ticketContract = response.result;
            this.hasTicketContract = response.status;
          },
          error => this.errorUtility.showError(21, error),
          () => {
          }
        );
    }
  }

  sendUpdadeTicketContract() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.updateTicketContractForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.updateTicketContractForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .updateTicketContract(this.updateTicketContractForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "Contrato do cartão ingresso atualizado com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  getInstallmentDescription(id) {
    if(id != '') {
      this.weAdminService
        .getInstallmentDescription(id)
        .subscribe(
          response => {
            this.installmentDescription = response.result;
            this.hasInstallmentDescription = response.status;
          },
          error => this.errorUtility.showError(21, error),
          () => {
          }
        );
    }
  }

  sendUpdadeInstallmentDescription() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.updateInstallmentDescriptionForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.updateInstallmentDescriptionForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .updateInstallmentDescription(this.updateInstallmentDescriptionForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "Descrição da parcela atualizada com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  getInstallmentStatus(id) {
    if(id != '') {
      this.weAdminService
        .getInstallmentStatus(id)
        .subscribe(
          response => {
            this.installmentStatus = response.result;
            this.hasInstallmentStatus = response.status;
          },
          error => this.errorUtility.showError(21, error),
          () => {
          }
        );
    }
  }

  CpfIntegration() {
    this.searchLoading = true;
    let data = {
      'cpf': this.integrationForm.value.cpf,
      'mifare': this.integrationForm.value.mifare,
      'idPartida': this.integrationForm.value.idPartida
    }

    this.weAdminService.checkCpf(data).subscribe(
      response => {

        if(response.status){

          this.dataResult = response.user
          // console.log('1:dataResult',response.user)
  
          let exemplo: DialogData = {
            result: this.dataResult,
            displayedColumns: ['socio']
          };
          this.openDialog()
        }else{
          this.searchLoading = false;
          this.showAlert(
            "Erro de consulta",
            "Dados não encontrados",
            "error"
            )
        }


      },err=>{        
        this.searchLoading = false;
        this.showAlert(
          "Erro de consulta",
          "Dados não encontrados",
          "error"
          )
        },
        () =>{
        this.searchLoading = false;

         }
    )
    
  }

  sendUpdadeInstallmentStatus() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.updateInstallmentStatusForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.updateInstallmentStatusForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .updateInstallmentStatus(this.updateInstallmentStatusForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "Status da parcela atualizada com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  getInstallmentValues(id) {
    if(id != '') {
      this.weAdminService
        .getInstallmentValues(id)
        .subscribe(
          response => {
            this.installmentValues = response.result;
            this.hasInstallmentValues = response.status;
          },
          error => this.errorUtility.showError(21, error),
          () => {
          }
        );
    }
  }

  sendUpdadeInstallmentValues() {
    try {
      window.scroll(0, 0);
      (<any>Object)
        .values(this.updateInstallmentValuesForm.controls)
        .forEach(control => {
          control.markAsTouched();
          control.markAsDirty();
        });

      if (this.updateInstallmentValuesForm.valid) {
        //this.contratoAlterarStatusLoading = true;
        this.weAdminService
          .updateInstallmentValues(this.updateInstallmentValuesForm.value)
          .subscribe(
            response => {
              if (response.status) {
                swal({
                  title: "Sucesso",
                  text: "Valores da parcela atualizada com sucesso!",
                  type: "success",
                  buttonsStyling: false,
                  animation: false,
                  customClass: "app-swal animated zoomIn faster",
                  confirmButtonClass: "primary-button",
                  cancelButtonClass: "secundary-button",
                  confirmButtonText: "OK"
                }).then(result => {
                  if (result.value) {
                    location.reload();
                  }
                });
              } else {
                this.errorUtility.showError(null, response.mensagem)
              }
            },
            error => this.errorUtility.showError(null, error),
            () => {
            }
          );
      } else {
        this.errorUtility.showError(
          null,
          "Preencha as informações corretamente"
        );
      }
    } catch (e) {
      this.errorUtility.showError(
        null,
        "Ocorreu um erro! Tente recarregar a página ou tente em um outro dispositivo."
      );
    }
  }

  optionsDuration(){
    this.duration = [
      {'duration':'6'},
      {'duration':'12'}
    ]
    
  }
  selectContract(event){
    if(event !=''){
      
      let data = {
        'idContrato':event
      }

      this.weAdminService.selectContractNumber(data).subscribe(
        
        response => {
          this.searchLoading = true;
          if(response.status){
            this.resultnumberContract = response[0].num_contrato
          }else{
            this.searchLoading = false;
            this.showAlert(
              "Erro de consulta",
              "Estatutário não encontrado",
              "error"
              )
          }
        
        },err=>{        
          this.searchLoading = false;
          this.showAlert(
            "Erro de consulta",
            "Estatutário não encontrado",
            "error"
            )
          },
        () => {
          this.searchLoading = false;
        }
      );
    }
  }
  changeNumberContract(){
    let data = {
      'idContrato':this.editContract.value.idContrato,
      'numContrato':this.editContract.value.numContrato,
    }
    this.weAdminService.changeNumberContract(data).subscribe(
      
      response => {
        this.searchLoading = true;
        if(response.status){
          swal({
            title: "Sucesso",
            text: "Numero  de Contrato atualizado com sucesso!",
            type: "success",
            buttonsStyling: false,
            animation: false,
            customClass: "app-swal animated zoomIn faster",
            confirmButtonClass: "primary-button",
            cancelButtonClass: "secundary-button",
            confirmButtonText: "OK"
          }).then(result => {
            if (result.value) {
               location.reload();
             }
           });
        }else{
          if(response.searched){
            this.searchLoading = false;
            this.showAlert(
              "Erro de Casdastro",
              "número do contrato já existe",
              "error"
              )
          }else{
            this.searchLoading = false;
            this.showAlert(
              "Erro de Casdastro",
              "Não foi possível realizar a operação",
              "error"
              )
          }
        }
       
      },err=>{        
        this.searchLoading = false;
        this.showAlert(
          "Erro de consulta",
          "Dados não encontrada",
          "error"
          )
        },
      () => {
        this.searchLoading = false;
        
      }
    );

  }

  

  selectInstallments(TransferOrigin){
    let data = {
      'idContrato': TransferOrigin
     }
     this.searchLoading = true;
     this.weAdminService.installmentsPartner(data).subscribe(
       
       response => {
        if(response.status){
          this.dataSource = response.result
          this.hideTable = true;
        }else{
          this.searchLoading = false;
          swal({
            title: "Erro",
            text: "Contrato  ou Parcelas não encontrado!",
            type: "error",
            buttonsStyling: false,
            animation: false,
            customClass: "app-swal animated zoomIn faster",
            confirmButtonClass: "primary-button",
            cancelButtonClass: "secundary-button",
            confirmButtonText: "retornar"
          }).then(result => {
            
              location.reload();
            
          });
          // this.showAlert(
          //   "Erro de consulta",
          //   "Dados não encontrados",
          //   "error"
          //   )
        }
        
      },err=>{        
        this.searchLoading = false;
        swal({
          title: "Erro",
          text: "Contrato  não encontrado!",
          type: "error",
          buttonsStyling: false,
          animation: false,
          customClass: "app-swal animated zoomIn faster",
          confirmButtonClass: "primary-button",
          cancelButtonClass: "secundary-button",
          confirmButtonText: "retornar"
        }).then(result => {
          
            location.reload();
          
        });
        },
        () => {
          this.searchLoading = false;
          
        }
        );
        
      }

      checkSelection(event){
    
        if(event.target.firstChild.checked){
          this.checkedId = false;
        }else{
          this.checkedId = true;
        }
        
      }

    selectInstallmentTransfer(idInstallment, index, status){
          
        if(status.checked){
    
          this.installmentResult.push(idInstallment)
          console.log(this.installmentResult)
          
        }else{
    
          for (let index = 0; index <  this.installmentResult.length; index++) {
            
          if(this.installmentResult[index] == idInstallment){
            this.installmentResult.splice(index,1)
            console.log(this.installmentResult)
          } 

          }   
          
        }
        
      }

  installmentsPartner(){
    this.searchLoading = true;
    let data;
    let dataTransfer;
    let typeTransfer;

    if(this.checkedId){
      dataTransfer = {
        'idContractOrigin': this.installmentsUpdate.value.TransferOrigin,
        'idContractDestiny': this.installmentsUpdate.value.TransferDestiny,
        'status':1
       }
       typeTransfer = 1;
    }
    else{
      dataTransfer = {
        'idContractOrigin': this.installmentsUpdate.value.TransferOrigin,
        'idContractDestiny': this.installmentsUpdate.value.TransferDestiny,
        'installments': this.installmentResult,
        'status':2
       }
       typeTransfer = 2;
    }

    if(typeTransfer == 1){
      data = dataTransfer;
    }else{
      data = dataTransfer;
    }

    this.weAdminService.transferInstallmentsPartner(data).subscribe(
      
      response => {
        if(response.status){
          swal({
            title: "Sucesso",
            text: "Transferência de Parcelas Realizadas com sucesso!",
            type: "success",
            buttonsStyling: false,
            animation: false,
            customClass: "app-swal animated zoomIn faster",
            confirmButtonClass: "primary-button",
            cancelButtonClass: "secundary-button",
            confirmButtonText: "OK"
          }).then(result => {
            if (result.value) {
              location.reload();
            }
          });


        }else{
          this.searchLoading = false;
          swal({
            title: "Erro",
            text: "Não foi possível realizar a Transferência",
            type: "error",
            buttonsStyling: false,
            animation: false,
            customClass: "app-swal animated zoomIn faster",
            confirmButtonClass: "primary-button",
            cancelButtonClass: "secundary-button",
            confirmButtonText: "retornar"
          }).then(result => {
            
              location.reload();
            
          });
            
        }
       
      },err=>{        
        this.searchLoading = false;
        swal({
          title: "Erro",
          text: "Não foi possível realizar a Transferência",
          type: "error",
          buttonsStyling: false,
          animation: false,
          customClass: "app-swal animated zoomIn faster",
          confirmButtonClass: "primary-button",
          cancelButtonClass: "secundary-button",
          confirmButtonText: "retornar"
        }).then(result => {
          
            location.reload();
          
        });
          
        },
      () => {
        this.searchLoading = false;
        
        
      }
    );
    
  }

 
  changeInstallment(hide){
    if(hide){
      this.hide = true;
    }else{
      this.hide = false;
      //chama função para transferir todas
    }
  }

  planDuration(){

    let data = {
      'duracao':this.updateContractPaymentForm.value.duracao,
      'idcontrato':this.updateContractPaymentForm.value.idcontrato
    }
    this.weAdminService.changeplanduration(data).subscribe(
      
      response => {
  
       
      },err=>{        
        this.searchLoading = false;
        this.showAlert(
          "Erro de consulta",
          "contrato não encontrado",
          "error"
          )
        },
      () => {
        this.searchLoading = false;
        
      }
    );
  }

  ChangeParcels(){
    let newDate = moment(this.dateParcelsForm.value.newDate.format("YYYY/MM/DD"))

    let data = {
      'idContaReceber': this.dateParcelsForm.value.idContaReceber,
      'dtVencimento': newDate['_i']
    }

    this.weAdminService.updateDateInstallment(data).subscribe(
      
      response => {
        this.searchLoading = true;
        if(response.status){

          swal({
            title: "Sucesso",
            text: "data de Vencimento da parcela atualizada com sucesso!",
            type: "success",
            buttonsStyling: false,
            animation: false,
            customClass: "app-swal animated zoomIn faster",
            confirmButtonClass: "primary-button",
            cancelButtonClass: "secundary-button",
            confirmButtonText: "OK"
          }).then(result => {
            if (result.value) {
               location.reload();
             }
           });
        }else{
          this.searchLoading = false;
          this.showAlert(
            "Erro de consulta",
            "Dados não encontrados",
            "error"
            )
        }


      },err=>{        
        this.searchLoading = false;
        this.showAlert(
          "Erro de consulta",
          "Dados não encontrados",
          "error"
          )
        },
        () =>{
        this.searchLoading = false;
         }
    )

  }

  openDialog(){
    const dialogRef = this.dialog.open(DialogCheckCpf, {
      width: '800px', height: '600',
      data: {result: this.dataResult}
    });

   }

  showAlert(title, message, type) {
    this.searchLoading = false;
    swal({
      //
      buttonsStyling: false,
      animation: false,
      customClass: "app-swal animated zoomIn faster",
      confirmButtonClass: "primary-button",
      cancelButtonClass: "secundary-button",
      //
      title: title,
      text: message,
      type: type,
      confirmButtonText: "OK"
    });
  };
}



@Component({
  selector: 'dialog-check-cpf',
  templateUrl: 'dialog-check-cpf.html',
  styleUrls: ['./dialog-check-cpf.scss']
})
export class DialogCheckCpf {

  public result: any


  public displayedColumns: string[] = ['socio'];

constructor(
  public dialogRef: MatDialogRef<DialogCheckCpf>,
  public weAdminService: WeAdminService,
  @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.result = [data.result]
    // console.log('2inject result', this.result)
    
  }
      
  onNoClick(){
    
      this.dialogRef.close();
    }
  }

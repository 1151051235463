import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { WEUserAdmin } from '../../../../app/models/we-model';
import { InfoUtility } from '../../../../app/utils/info-utility';
import { ModalUserInfoComponent } from '../modals/modal-user-info/modal-user-info.component';

import swal from 'sweetalert2';
import { ErrorUtility } from 'src/app/utils/error-utility';

@Component({
	selector: 'app-documents-table',
	templateUrl: './documents-table.component.html',
	styleUrls: ['./documents-table.component.scss'],
	providers: [InfoUtility]
})
export class DocumentsTableComponent implements OnInit, OnChanges {
	public displayedColumns: string[] = ['nome', 'cpf', 'btn', 'btn2'];
	public dataSource = new MatTableDataSource<WEUserAdmin>();

	@Input() users: Array<WEUserAdmin>;
	@Input() hide: any;
	@Output() submitApprovalEvent = new EventEmitter();
	@Output() submitUserStatusEvent = new EventEmitter();
	@Input() filterStatus: string;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	public userStatus: number;

	public selectedUsers: Array<WEUserAdmin> = [];
	
	ngOnChanges() {
		this.dataSource.data = this.users;
		
		if(this.dataSource.data){
			for (let i = 0; i <= this.dataSource.data.length; i++) {
				this.dataSource.data[i].detailsOpened =  false;
			}
		}
		// for (let i = 0; i <= this.users.length; i++) {
		// 	if (this.users[i].hasOwnProperty('documento')) {
				
				
		// 		if (this.users[i].documento.residence.file) {
		// 			this.users[i].documento.residence.type = this.users[i].documento.residence.file.substr(
		// 				this.users[i].documento.residence.file.lastIndexOf('.') + 1
		// 			);
		// 		}
	
		// 		if (this.users[i].documento.cpf.file) {
		// 			this.users[i].documento.cpf.type = this.users[i].documento.cpf.file.substr(
		// 				this.users[i].documento.cpf.file.lastIndexOf('.') + 1
		// 			);
		// 		}
	
		// 		if (this.users[i].documento.identity.file) {
		// 			this.users[i].documento.identity.type = this.users[i].documento.identity.file.substr(
		// 				this.users[i].documento.identity.file.lastIndexOf('.') + 1
		// 			);
		// 		}
		// 	}
		// 	//  this.users[i].dt_nascimento = this.infoUtility.getDateFormat(this.users[i].dt_nascimento);
		// 	// TODO: Api tem que me retornar o filetype
		// }
		// }
	}

	constructor(public infoUtility: InfoUtility, public dialog: MatDialog, public errorUtility: ErrorUtility) { }

	ngOnInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		
	}

	approveAll() {
		this.applySelectedUserStatus(this.selectedUsers, 1);
	}

	applySelectedUserStatus(_user: Array<WEUserAdmin>, _value) {
		var status: string;
		var statusNumber: number;
		console.log(_value);
		if (_value == 1) {
			status = 'APROVAÇÃO DE DOCUMENTOS';
			statusNumber = 1;
		} else if (_value == 2) {
			status = 'SOLICITAÇÃO DE REVISÃO DE DOCUMENTOS';
			statusNumber = 2;
		} else if (_value == 3) {
			status = 'RECUSA DE DOCUMENTOS';
			statusNumber = 3;
		} else {
			this.errorUtility.showMsg('error', 'Erro', 'Não foi possível alterar o status do usuário', 'OK');
		}

		if (!status || !statusNumber) {
			this.errorUtility.showMsg('error', 'Erro', 'Não foi possível enviar os dados para a ' + status, 'OK');
			return;
		}
		swal({
			title: 'Aviso!',
			text: 'Essa ação não poderá ser desfeita. Deseja continuar com a ' + status + ' de todos os usuários selecionados?',
			type: 'warning',
			//
			showCancelButton: true,
			buttonsStyling: false,
			animation: false,
			customClass: 'app-swal animated zoomIn faster',
			confirmButtonClass: 'primary-button',
			cancelButtonClass: 'secundary-button',
			//
			confirmButtonText: 'Sim',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if (result.value) {
				for(let i=0;i<=_user.length - 1;i++){
					this.submitUserStatusEvent.emit({
						idpessoa: _user[i].idpessoa,
						status: statusNumber
					});
				}
			} else {
				// for (let i = 0; i < this.users.length; i++) {
				// 	if (this.users[i].idpessoa == _user.idpessoa) {
				// 		this.users[i].userStatus = '';
				// 	}
				// }
			}
		});

	}

	selectUser(idpessoa){
		let index = this.selectedUsers.indexOf(idpessoa);
		if(index == -1){
			this.selectedUsers.push(idpessoa);
		}else{
			this.selectedUsers.splice(index, 1);
		}
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openModal(_element, _mode) {
		let dialogRef = this.dialog.open(ModalUserInfoComponent, {
			height: '100%',
			data: {
				user: _element,
				mode: _mode
			},
			panelClass: 'app-modal-user-info'
		});

		dialogRef.afterClosed().subscribe(
			(response) => {
				
			},
			(error) => {
				console.log(error);
			},
			() => { }
		);
	}

	applyStatus(_user: WEUserAdmin, _mode: string, _value: string) {
		this.submitApprovalEvent.emit({
			idpessoa: _user.idpessoa,
			document: _mode,
			approved: _value
		});
	}

	openDetails() {

	}

	applyUserStatus(_user: WEUserAdmin, _value) {
		var status: string;
		var statusNumber: number;
		console.log(_value);
		if (_value == 1) {
			status = 'APROVAÇÃO DE DOCUMENTOS';
			statusNumber = 1;
		} else if (_value == 2) {
			status = 'SOLICITAÇÃO DE REVISÃO DE DOCUMENTOS';
			statusNumber = 2;
		} else if (_value == 3) {
			status = 'RECUSA DE DOCUMENTOS';
			statusNumber = 3;
		} else {
			this.errorUtility.showMsg('error', 'Erro', 'Não foi possível alterar o status do usuário', 'OK');
		}

		if (!status || !statusNumber) {
			this.errorUtility.showMsg('error', 'Erro', 'Não foi possível enviar os dados para a ' + status, 'OK');
			return;
		}
		swal({
			title: 'Aviso!',
			text: 'Essa ação não poderá ser desfeita. Deseja continuar com a ' + status + ' do usuário?',
			type: 'warning',
			//
			showCancelButton: true,
			buttonsStyling: false,
			animation: false,
			customClass: 'app-swal animated zoomIn faster',
			confirmButtonClass: 'primary-button',
			cancelButtonClass: 'secundary-button',
			//
			confirmButtonText: 'Sim',
			cancelButtonText: 'Cancelar'
		}).then((result) => {
			if (result.value) {
				this.submitUserStatusEvent.emit({
					idpessoa: _user.idpessoa,
					status: statusNumber
				});
			} else {
				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i].idpessoa == _user.idpessoa) {
						this.users[i].userStatus = '';
					}
				}
			}
		});
		
	}
}

import { Injectable } from "@angular/core";

import { Subject } from "rxjs/index";

@Injectable({
  providedIn: "root"
})
export class LoadingService {
  public isLoading: Subject<boolean> = new Subject<boolean>();
  public fengUpdateMyAccount: Subject<boolean> = new Subject<boolean>();
  public fengUserUpdate: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  public updateUser() {
    this.fengUserUpdate.next(true);
  }

  public updateMyAccount() {
    this.fengUpdateMyAccount.next(true);
  }

  public startLoading() {
    this.isLoading.next(true);
  }

  public finishLoading() {
    this.isLoading.next(false);
  }

  public showAlert(msg: string) {
    // console.log(msg);
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { SearchService } from '../../shared/services/search.service';
import { ClickOutsideDirective } from 'ng-click-outside';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {

  public showSearchBar: boolean;
  @Output() toggleSideMenu = new EventEmitter();
  @Input() selectedItemFromMenu: string = "";
  @Input() user: any = {};

  constructor(public searchbarService: SearchService) {}

  ngOnInit() {
    this.searchbarService.getSearchbar().subscribe(
      res=>this.showSearchBar = res
    )
  }
  onClickedOutside(e: Event) {
    this.searchbarService.setSearchbar(false);
  }

  openSideMenu() {
    this.toggleSideMenu.emit(true);
  }

  /**
   * Abre ou Fecha a Busca
   */
  toggleSearch(e) {
    e.stopPropagation();
    this.searchbarService.setSearchbar(!this.showSearchBar);
  }



}

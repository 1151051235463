import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import swal from "sweetalert2";
import { AuthService } from "../../shared/services/auth.service";
import { Router, Params, ActivatedRoute } from "@angular/router";
import { Config } from '../../../app/app.config';

@Component({
  selector: "app-sidemenu",
  templateUrl: "./sidemenu.component.html",
  styleUrls: ["./sidemenu.component.scss"],
  providers: [Config]
})
export class SidemenuComponent implements OnInit {
  @Output() toggleSideMenu = new EventEmitter();
  @Output() selectedItemMenuChanged = new EventEmitter(true);

  public lottieConfig: Object;

  public selectedItem: string = "";

  public pages: Array<{ title: string; icon: string; url: string }> = [
    {
      title: "Dashboard",
      icon: "icon-gs-home",
      url: "dashboard"
    },
    {
      title: "Documentos",
      icon: "icon-gs-docs",
      url: "docs"
    },
    // {
    //   title: "Ferramentas",
    //   icon: "icon-gs-wrench",
    //   url: "ferramentas"
    // },
    {
      title: "Logout",
      icon: "icon-gs-logout",
      url: "logout"
    }
  ];

  constructor(
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public config: Config
  ) {
    this.lottieConfig = {
      path: "assets/animations/id.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }

  ngOnInit() {
    this.checkParams();
  }

  ngAfterViewInit() {
    this.sendPageToHeader();
  }

  checkParams() {
    switch (this.router.url) {
      case "/":
        this.selectedItem = "dashboard";
        break;

      case "/docs":
        this.selectedItem = "docs";
        break;

      case "/dashboard":
        this.selectedItem = "dashboard";
        break;

      default:
        break;
    }
  }

  openPage(page) {
    switch (page.url) {
      case "logout":
        this.logout();
        break;

      default:
        this.router.navigate([page.url]);
        this.selectedItem = page.url;
        break;
    }
    this.sendPageToHeader();
    this.toggleSideMenu.emit(false);
  }

  sendPageToHeader() {
    for (let i = 0; i < this.pages.length; i++) {
      if (this.pages[i].url == this.selectedItem) {
        this.selectedItemMenuChanged.emit(this.pages[i].title);
      }
    }
  }

  closeSideMenu() {
    this.toggleSideMenu.emit(false);
  }

  logout() {
    swal({
      title: "Tem certeza que deseja fazer logout?",
      type: "warning",
      //
      showCancelButton: true,
      buttonsStyling: false,
      animation: false,
      customClass: "app-swal animated zoomIn faster",
      confirmButtonClass: "primary-button",
      cancelButtonClass: "secundary-button",
      //
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    }).then(result => {
      if (result.value) {
        this.authService.logout();
      }
    });
  }


}

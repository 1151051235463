import { Component, OnInit, Inject,NgModule } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SearchService } from '../../../shared/services/search.service';
import { WeAdminService } from "../../../services/we-admin.service";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import swal from "sweetalert2";
import { forEach } from '@angular/router/src/utils/collection';

export interface DialogData {
  origin: any;
  destiny: any;
  displayedColumns: any;
  originChange: any;
  destinyChange:any;
}


@Component({
  selector: 'app-transferencia-titulo',
  templateUrl: './transferencia-titulo.component.html',
  styleUrls: ['./transferencia-titulo.component.scss']
})
export class TransferenciaTituloComponent implements OnInit {

  public teste: string;
  public transferTitleForm: FormGroup;
  public idContractOrigin;
  public idOriginPeople;
  public menssageError;
  public statusError;
  public pay;
  public frequency;
  public contractOrigin;
  public hide = false;
  public confirmView = false;

  public users1;
  public users2;
  public dataSource;

  public idformapag;
  public dscformapag;
  public contractPaymentMethodOptions = [];
  
  public contractPaymentPeriodicityOptions = [];

  public searchContractPayment = [];
  public searchContractPeriodicity = [];

  public searchTypePayment = [];
  public searchTypePeriodicity = [];

  public searchLoading: boolean = false;
  constructor(public formBuilder: FormBuilder,
              public searchService: SearchService,
              public weAdminService: WeAdminService,
              public dialog: MatDialog
              ) { }

  ngOnInit() {
    // this.initData();
    this.setFormGroups();
    // this.setContractPaymentOptions();
    
  }

   /**
     * Inicializa Dados
     */
  // initData() {

  //   this.transferTitleForm = this.formBuilder.group({
  //     idpessoaOrigem: [null, Validators.minLength(3)],
  //     idpessoaDestino: [null, Validators.minLength(3)]
  //   });
  // }

  setFormGroups() {
    this.transferTitleForm = this.formBuilder.group({
      idContractOrigin: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      idPeopleDestiny: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
      idPeopleOrigin: [
        "",
        Validators.compose([
          Validators.required
        ])
      ],
    });
  };

  /**
   * 
   * Busca Dados da pessoa
   */
  // peopleOrigin(idContractOrigin) {
  //   this.idContractOrigin = idContractOrigin;
  //   // this.getContractPaymentOptions();
  // }

  // getContractPaymentOptions() {
  //   this.weAdminService.getContractPaymentOptions().subscribe(
  //     response => {
  //       if(response.result) {

          
         
  //         this.checkplan(this.idContractOrigin, response.result.payment_methods,  response.result.periodicity);
          
  //       }
  //     }
  //   )
  // }

  // checkplan(idContractOrigin, payment, periodicity){
  //   this.searchLoading = true;
  //   this.weAdminService.getcheckplan(idContractOrigin).subscribe(
  //     response => {
        

  //        for (const key in payment) {
  //          if (payment.hasOwnProperty(key)) {
  //            const element = payment[key];
  //             for (const key2 in response.regras) {
  //               if (response.regras.hasOwnProperty(key2)) {
  //                 const element2 = response.regras[key2];
                  
  //                 if(element.idformapag == element2.idformapag){
  //                  this.contractPaymentMethodOptions.push(element)
       
  //                  // public contractPaymentPeriodicityOptions = [];
                 
  //                 }
  //               }
  //             }
             
  //          }

  //         }
  //   },
  //   () =>{
  //     this.searchLoading = false;
  //     }
  //     )
  //     this.contractPaymentPeriodicityOptions
  //   }

  // peopleDestiny(idPeopleDestiny){
  //   this.idPeopleDestiny = idPeopleDestiny;
  //   this.postTransferTitle();
  // }


  // teste = {}
  // setContractPaymentOptions() {
  //   this.weAdminService.postPlanRules(idplan).subscribe(
  //     response => {
  //       if(response.status) {

  //         for (const key in response.result.payment_methods) {
  //           if (response.result.payment_methods.hasOwnProperty(key)) {
  //             const element = response.result.payment_methods[key];
             
  //             if(element.idformapag = 'c2724b280bd37a5379e2e1d98163d8ab'){

  //               this.contractPaymentMethodOptions = element.push()
  //             }
                                 
  //           }
  //       }
         
  //       }
  //     },
  //     err=>{        
  //       this.showAlert(
  //         "Erro de consulta",
  //         "Dados não encontrados",
  //         "error"
  //       )
  //     }
  //   );
  // }
  
  // payment(pay){
  // this.pay = pay
    
  // }

  // paymentFrequency(frequency){
  //   this.frequency = frequency
  // }

  selectTitle(){
    this.searchLoading = true;
    let  data = {
      'idContractOrigin': this.transferTitleForm.value.idContractOrigin,
      'idPeopleDestiny': this.transferTitleForm.value.idPeopleDestiny,
      'idPeopleOrigin': this.transferTitleForm.value.idPeopleOrigin
    }; 

    this.weAdminService.selectTitle(data).subscribe(
      response=> {
        // if(response.data.status == true)
        if(response.status){
          this.users1 = response.dataOrigin.origin;
          this.users2 = response.dataDestiny.destiny;
         
          let exemplo: DialogData = {
            origin: this.users1,
            destiny: this.users2,
            originChange: this.transferTitleForm.value.idPeopleOrigin,
            destinyChange: this.transferTitleForm.value.idPeopleDestiny,
            displayedColumns: ['cpf', 'nome', 'plano']
          };
          this.statusError = response.status;
        }        
        
      },err=>{   
      
        this.searchLoading = false;
        this.showAlert(
          "Erro de consulta",
          err,
          "error"
          )
        },
        () =>{
          this.openDialog()
          this.searchLoading = false;
          this.hide = true;
      }
      );
   
  }

  /**
   * Transfere Título de Sócio Estatutário de um Sócio para o Outro
   */
  postTransferTitle() {
    this.searchLoading = true;
    let  dataTransfer = {
      'idContractOrigin': this.transferTitleForm.value.idContractOrigin,
      'idPeopleDestiny': this.transferTitleForm.value.idPeopleDestiny,
      'idPeopleOrigin': this.transferTitleForm.value.idPeopleOrigin,
      // 'pay': this.pay,
      // 'frequency': this.frequency
    }; 

    this.weAdminService.postTransferTitle(dataTransfer).subscribe(
      response=> {
        if(response.status) {
          console.log('Transferência realizada com sucesso')
        }
      } ,err=>{        
        this.searchLoading = false;
        this.showAlert(
          "Erro na transferência",
          err,
          "error"
        )
      },
      () =>{
        this.searchLoading = false;
        this.hide = true;
        this.showAlert("Sucesso", "Transferência concluída", "success");
      }
      
    )
    
  };

  showAlert(title, message, type) {
    this.searchLoading = false;
    swal({
      //
      buttonsStyling: false,
      animation: false,
      customClass: "app-swal animated zoomIn faster",
      confirmButtonClass: "primary-button",
      cancelButtonClass: "secundary-button",
      //
      title: title,
      text: message,
      type: type,
      confirmButtonText: "OK"
    });
  };

  openDialog(){
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '800px', height: '600',
      data: {origin: this.users1, destiny: this.users2}
    });

    dialogRef.afterClosed().subscribe(ex=>{
      if(ex){

        this.postTransferTitle()
      }
    })
   }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html'
})
export class DialogOverviewExampleDialog {

  public dataOrigin: any
  public dataDestiny: any
  public originChange:any
  public destinyChange:any

  public displayedColumns: string[] = ['nome','cpf', 'plano'];

constructor(
  public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
  public weAdminService: WeAdminService,
  @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.dataOrigin = [data.origin[0]]
    this.dataDestiny = [data.destiny[0]]
    this.originChange = [data.originChange]
    this.destinyChange = [data.destinyChange]
    
  }
      
  onNoClick(bol): void {
    if(bol == true){
      this.dialogRef.close([this.originChange,this.destinyChange]);
    }else{
      this.dialogRef.close();
    }
  }

}

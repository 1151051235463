import { Injectable } from "@angular/core";

import swal from "sweetalert2";

@Injectable()
export class ErrorUtility {
  constructor() {}

  showMsg(type, title, text, confirmButtonText) {
    //Types: 'warning', 'error', 'success', 'info', 'question'

    swal({
      //
      buttonsStyling: false,
      animation: false,
      customClass: "app-swal animated zoomIn faster",
      confirmButtonClass: "primary-button",
      cancelButtonClass: "secundary-button",
      //
      title: title,
      text: text,
      type: type,
      confirmButtonText: confirmButtonText
    });
  }

  showError(cod, error) {
    let title = "Erro";
    if (cod) {
      title = "Erro - Código: " + cod;
    }

    if (cod == 21) {
      console.warn(cod + ' - ' + error);
    } else {
      swal({
        //
        buttonsStyling: false,
        animation: false,
        customClass: "app-swal animated zoomIn faster",
        confirmButtonClass: "primary-button",
        cancelButtonClass: "secundary-button",
        //
        title: title,
        text: error,
        type: "error",
        confirmButtonText: "OK"
      });
    }
  }

  showSuccess(msg) {
    swal({
      buttonsStyling: false,
      animation: false,
      customClass: "app-swal animated zoomIn faster",
      confirmButtonClass: "primary-button",
      cancelButtonClass: "secundary-button",
      title: 'Sucesso',
      text: msg,
      type: "success",
      confirmButtonText: "OK"
    });
  }
}

import { WeAuthService } from './../../services/we-auth.service';
import { CustomValidators } from './../../utils/custom-validators';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import swal from 'sweetalert2';

import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ErrorUtility } from 'src/app/utils/error-utility';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [CustomValidators, BrowserAnimationsModule, WeAuthService]
})
export class LoginComponent implements OnInit {
	public isSignUp: boolean;
	public isRecovery: boolean;
	public errorMsg: string;

	public signinFormGroup: FormGroup;

	constructor(
		public formBuilder: FormBuilder,
		public router: Router,
		public customValidator: CustomValidators,
		public weAuthService: WeAuthService,
		public authService: AuthService,
		public loadingService: LoadingService,
		public errorUtility: ErrorUtility,
		private localStorage: LocalStorage
	) { }

	ngOnInit() {
		this.isSignUp = false;
		this.applyAuthMode();
		this.setFormGroups();
	}

	applyAuthMode() { }

	setFormGroups() {
		this.signinFormGroup = this.formBuilder.group({
			login: ['', Validators.compose([Validators.maxLength(120), Validators.required])],
			password: ['', Validators.required]
		});
	}

	doSignIn() {
		if (this.signinFormGroup.valid) {
			this.loadingService.startLoading();
			this.weAuthService.postLogin(this.signinFormGroup.value).subscribe(
				(response) => {
					if (response.res.user && response.res.auth.token != undefined) {
						this.authService.saveData(response.res, '/');
						this.proceedToHome();
					} else {
						console.log('Error');
						this.loadingService.finishLoading();
					}
				},
				(error) => {
					console.log('Error');
					this.errorUtility.showMsg('error', 'Login inválido', error, 'Ok');
					this.loadingService.finishLoading();
				},
				() => { }
			);
		} else {
			this.loadingService.finishLoading();
		}
	}

	proceedToHome() {
		this.authService.isAuthenticated();

		// Await token to proceed
		this.localStorage.getItem("fengst-admin-token").subscribe(() => {
			this.router.navigate(['dashboard']);
		}, err => {
			console.log(err);
		}, () => {
			this.loadingService.finishLoading();
		})


	}
}

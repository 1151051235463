import { NgModule } from "@angular/core";
import {
  MatInputModule,
  MatMenuModule,
  MatDialogModule,
  MatTabsModule,
  MatIconModule,
  MatCardModule,
  MatSortModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatTooltipModule,
  MatGridListModule,
  MatExpansionModule,
  MatSnackBarModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatRadioModule
} from "@angular/material";

import { MatMomentDateModule } from "@angular/material-moment-adapter";

@NgModule({
  imports: [
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    MatSortModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTooltipModule,
    MatGridListModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    MatSortModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTooltipModule,
    MatGridListModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatRadioModule
  ]
})
export class MaterialModule { }

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { isPlatformBrowser } from '@angular/common';

import { map } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ErrorUtility } from '../../utils/error-utility';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public isLoginSubject = new BehaviorSubject<boolean>(false);

	constructor(
		protected localStorage: LocalStorageService,
		protected jwtHelper: JwtHelperService,
		protected errorUtility: ErrorUtility,
		@Inject(PLATFORM_ID) protected platformId: Object,
		protected router: Router
	) {}

	isLoggedIn(): Observable<boolean> {
		return this.isLoginSubject.asObservable();
	}

	public isAuthenticated(): Observable<boolean> {
		if (!isPlatformBrowser(this.platformId)) {
			this.isLoginSubject.next(false);
			return new Observable();
		} else {
			return this.localStorage.getItem('fengst-admin-token').pipe(
				map((data: any) => {
					if (data == null) {
						this.isLoginSubject.next(false);
						return false;
					} else {
						let localAuth = !this.jwtHelper.isTokenExpired(data.slice(1, -1));
						this.isLoginSubject.next(localAuth);
						return localAuth;
					}
				})
			);
		}
	}

	saveData(res, naveg) {
		this.localStorage.setItem('fengst-admin-user', res.user).subscribe(
			(response) => {
				this.localStorage.setItem('fengst-admin-token', res.auth.token).subscribe(
					(response) => {
						let auth = !this.jwtHelper.isTokenExpired(res.auth.token);
						this.isLoginSubject.next(auth);
						if (naveg) {
							if (naveg == 'reload') {
								location.reload();
							} else {
								this.router.navigate([ naveg ]);
							}
						}
					},
					(error) => this.errorUtility.showError(22, error),
					() => {}
				);
			},
			(error) => this.errorUtility.showError(22, error),
			() => {}
		);
	}

	logout() {
		this.localStorage.removeAll();
		this.isLoginSubject.next(false);
		this.router.navigate([ 'login' ]);
	}
}

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { MAT_DIALOG_DATA } from "@angular/material";
import { WEUserAdmin } from "src/app/models/we-model";
import { PinchZoomModule } from 'ngx-pinch-zoom';

@Component({
  selector: "app-modal-user-info",
  templateUrl: "./modal-user-info.component.html",
  styleUrls: ["./modal-user-info.component.scss"]
})
export class ModalUserInfoComponent implements OnInit {
  public lottieConfig: Object;
  public user: WEUserAdmin;
  public mode: string = "";
  public imageUrl: string = "";
  public isPdf: boolean;
  public datas: any;
  public currentPdf: any = null; // I guess the correct data type is "DOMString"

  set src(input: Blob) {
    this.currentPdf = URL.createObjectURL(input);
  }

  constructor(
    public dialogRef: MatDialogRef<ModalUserInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public pinchZoomModule: PinchZoomModule
  ) {}

  ngOnInit() {
    this.lottieConfig = {
      path: "assets/animations/not_found.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
    this.user = this.data.user;
    this.mode = this.data.mode;
    
    console.log('user: ', this.user);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  downloadFile() {
    
  }

  zoom(e){
    let zoomer = e.currentTarget;
    let offsetX:number;
    let offsetY:number;
    let x:number;
    let y:number;
    
    e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
    e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
    x = offsetX/zoomer.offsetWidth*100
    y = offsetY/zoomer.offsetHeight*100
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
  } 
}

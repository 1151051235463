import { Injectable } from "@angular/core";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";

@Injectable()
export class InfoUtility {
  constructor(private http: HttpClient, public snackBar: MatSnackBar) {}

  getCEP(cep) {
    let url = "https://carioca.build/cep/?cep=" + cep;
    return this.http.get<any>(url);
  }

  getDateFormat(value) {
    // Adiciona formatação para date DD/MM/YYYY
    if (value != null) {
      var date = moment(value).format("DD/MM/YYYY");
      return date;
    }
  }

  getTimestampFormat(value) {
    // Adiciona formatação para timestamp
    if (value != null) {
      var date = moment(value).unix();
      return date;
    }
  }

  getCPFFormat(value) {
    // Adiciona formatação para CPF
    if (value != null) {
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      return value;
    }
  }

  getPhoneFormat(value) {
    // Muda telefone para (+XX) YY ZZZZZZZZZ
    if (value != null) {
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{0})(\d)/, "$1(+$2");
      value = value.replace(/(\d{2})(\d)/, "$1) $2");
      value = value.replace(/(\d{2})(\d)/, "$1 $2");
      return value;
    }
  }

  getRawData(_date) {
    var date = moment(_date).toJSON();
    return date;
  }

  showSnackBar(
    message: string,
    action: string,
    type: string,
    duration?: number
  ) {
    let snackbarDuration = duration ? duration : 2000;

    this.snackBar.open(message, action, {
      duration: snackbarDuration,
      panelClass: ["app-snackbar--" + type]
    });
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
}
